<template>
  <article class="disclosure">
    <h1>Our Whistleblower System</h1>
    <p>
      Complying with statutory regulations and internal rules, and the principles laid down in our Code of Conduct and
      the Code of Conduct for Business Partners, has top priority at Electrify America. The success of our company is
      based on Integrity and Compliance.
      <strong>
        To meet these standards, it is important to learn of potential employee or supplier misconduct and to put a stop
        to it.
      </strong>
      Therefore, we entrusted the Central Investigation Office to operate an independent, impartial, and confidential
      Whistleblower System on our behalf.
    </p>
    <p>
      A key pillar of our Whistleblower System is the principle of procedural fairness. It also guarantees the greatest
      possible protection for whistleblowers, persons implicated and employees contributing to the investigation of
      reported misconduct.
    </p>
    <p>
      This also includes offering opportunities for anonymous reporting and communication. We assure not to perform any
      steps in order to identify anonymous whistleblowers, who do not misuse our Whistleblower System. Retaliation
      against whistleblowers and all persons who contribute to investigations at Electrify America will not be
      tolerated. Persons implicated are presumed innocent until the violation is proven. Investigations will be
      conducted with the utmost confidentiality. The information will be processed in a fair, fast, and protected
      process.
    </p>
    <h2 class="headline3">How do we process your report?</h2>
    <p>
      The qualified and experienced colleagues at the Central Investigation Office examine every report for potential
      misconduct by an Electrify America employee thoroughly and follow it up systematically. First, you will get a
      confirmation of receipt. The Central Investigation Office then assesses your report. This includes gathering facts
      particularly from the whistleblower. Only if this initial evaluation shows grounds for suspicion of a violation an
      investigation by a dedicated Investigating Unit will be started. Afterwards, the results of the investigation will
      be assessed by the Central Investigation Office and appropriate measures will be recommended.
    </p>
    <p>
      Potential violations of the Code of Conduct for Business Partners by suppliers, including serious risks and
      violations of human rights and environment by direct and indirect suppliers, can also be reported to the Central
      Investigation Office - as well as reports requiring otherwise immediate action. The Central Investigation Office
      will inform the responsible departments, who will process the issue accordingly. This particularly includes taking
      the necessary measures to minimize or end violations and/or risks.
    </p>
    <p>
      More information on the respective procedural principles can be found
      <a
        class="link"
        rel="noopener"
        target="_blank"
        href="https://www.volkswagen-group.com/en/publications/more/rules-of-procedure-for-the-volkswagen-group-complaints-procedure-2007"
      >
        here.
      </a>
    </p>
    <h2 class="headline3">What kind of report do you want to submit?</h2>
    <h2 class="headline4">A complaint about products or services to our customer services</h2>
    <p>For complaints or feedback about Electrify America please contact:</p>
    <p>
      <strong>Phone: </strong> 1-833-632-2778 <br />
      <strong>Online: </strong>
      <router-link :to="{ name: 'contact-us-en' }" class="link">Electrify America</router-link> <br />
      <strong>Email: </strong>
      <a href="mailto:support@electrifyamerica.com" class="link">support@electrifyamerica.com</a> <br />
    </p>
    <p>
      Please understand that we will not be able to forward your request or take any action for reasons of
      responsibility.
    </p>
    <h2 class="headline4">A report of potential misconduct to our Whistleblower System</h2>
    <p>
      The Whistleblower System offers various channels for reporting potential misconduct by Electrify America
      employees, violations of the Code of Conduct for Business Partners, or serious risks and violations of human
      rights and environment in our Supply Chain.
    </p>

    <h2 class="headline5">Email</h2>

    <p>
      The Central Investigation Office of Volkswagen Group can be reached via E-mail (in any language):
      <a class="link" target="_blank" href="mailto:io@volkswagen.de">io@volkswagen.de</a>
    </p>
    <p>
      For customer complaints, please contact the channels listed under "A complaint about products or services to our
      customer service".
    </p>
    <h2 class="headline5">Postal Address / In Person</h2>
    <p><strong>Postal Address:</strong></p>
    <p>
      Volkswagen AG, Central Investigation Office <br />
      Mailbox 1717<br />
      Berliner Ring 2<br />
      38436 Wolfsburg, Germany<br />
    </p>

    <p><strong>In person:</strong></p>
    <p>
      Please make an appointment in advance by writing an E-mail to
      <a class="link" target="_blank" href="mailto:io@volkswagen.de">io@volkswagen.de</a>.
    </p>
    <h2 class="headline5">Ombudspersons</h2>
    <p>
      Volkswagen Group has appointed external lawyers to act as Ombudspersons. They advise on the Whistleblower System
      or ensure that reports from whistleblowers are forwarded anonymously to the Central Investigation Office if
      desired.
    </p>
    <p>
      If you want to get in contact with the Ombudsperson you can find their contact details here:
      <a class="link" target="_blank" href="http://www.ombudsmen-of-volkswagen.com/"
        >http://www.ombudsmen-of-volkswagen.com/</a
      >
    </p>
    <h2 class="headline5">Online Platform</h2>
    <p>
      You have the option of using a
      <a class="link" target="_blank" href="https://goto.speakup.report/volkswagen">
        web-based communication platform
      </a>
      to contact the Investigation Office in more than 65 languages. This system is confidential and technically secured
      and allows you to submit reports anonymously.
    </p>
    <p>
      You can access the online reporting channel through the following link:
      <a class="link" target="_blank" href="https://goto.speakup.report/volkswagen">
        https://goto.speakup.report/volkswagen
      </a>
    </p>
    <p>
      You can find detailed instructions on how to make reports online, by app, or by phone
      <a class="link" :href="require('../../../public/pdf/SpeakUp - How To Report.pdf')" rel="noopener" target="_blank"
        >here</a
      >.
    </p>

    <h2 class="headline5">Voice intake by phone</h2>
    <p>You can leave us a voice message by phone 24 hours a day, 7 days a week.</p>
    <p>
      After dialing the phone number, you will be prompted to enter the organization code. Then, you can submit your
      report as a voice recording. Only a written transcript will be received by the Central Investigation Office. Dial
      back in to listen to the reply or further questions by our team.
    </p>
    <div class="indent-list">
      <ol>
        <li>Please call +1 (669) 288 7154</li>
        <li>Have the organization code <strong>122203</strong> ready</li>
      </ol>
      <p>
        <strong>ATTENTION: </strong> If you are outside of the United States, you can select a number from
        <a
          class="link"
          :href="require('../../../public/pdf/Volkswagen Group SpeakUp Line Phone Numbers_VW.pdf')"
          rel="noopener"
          target="_blank"
          >this list</a
        >
        for the country or region you are in.
      </p>
    </div>
    <h2 class="headline3">Do you have any questions?</h2>
    <p>
      Questions or suggestions for improvement concerning the Whistleblower System can also be addressed to the Central
      Investigation Office.
    </p>
    <p>Furthermore, our local Compliance Officer can also be addressed in all matters of the Whistleblower System:</p>
    <ul>
      <li>Ethics Hotline: 888-228-7317</li>
      <li>
        Online:
        <a class="link" target="_blank" href="https://www.vwgoa.ethicspoint.com"> EthicsPoint </a>
      </li>
      <li>
        Email:
        <a class="link" target="_blank" href="mailto:vwgoacorporatecompliance@vw.com">
          Electrify America Compliance & Ethics
        </a>
      </li>
    </ul>
  </article>
</template>

<script>
export default {
  metaInfo: {
    title: `Whistleblower System | Electrify America`,
    meta: [
      {
        name: 'description',
        content: `Learn about our Whistleblower System and how to submit anonymous reports regarding a regulatory or code of conduct violation`,
      },
    ],
  },
};
</script>
<style lang="scss" scoped>
.indent-list {
  padding-left: 25px;
}

.headline3 {
  padding-top: 20px;
}

.headline4 {
  padding-top: 15px;
}
.headline5 {
  padding-top: 5px;
}
</style>
